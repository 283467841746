'use strict';

angular.module('enervexSalesappApp').controller('ProductCtrl', function($scope, Product, $stateParams, Flash, Auth, _, Util, Application, VentMaterial, Fitting, Baffle, ProductType, ProductSubtype, $state, Manufacturer, InsulationType, MetalType, $q, Fan, Image, FileUploader) {
	$scope.accountId = $stateParams.accountId;
	$scope.errors = {};
	$scope.isAdmin = Auth.isAdmin
	$scope.ctx = {
		formChanged: false
	}
	$scope.uploader = new FileUploader({});
	$scope.uploader.onAfterAddingFile = function() {
		$scope.ctx.formChanged = true;
	}
	Product.fields().$promise.then(function(result){
		$scope.allFields = result
	})
	$scope.toUrl = function(snippet){
		if ($scope.accountId){
			return "/accounts/"+$scope.accountId + snippet;
		} else {
			return snippet;
		}
	}

	function toNotes(product) {
		if (product.schedule && product.schedule.notes) {
			return _.map(product.schedule.notes, function(n){
				return {
					value:n
				}
			})
		} else {
			return []
		}
	}

	if ($stateParams.id == "new") {
		$scope.item = {
			shape: 'ROU',
			schedule: {
				notes: []
			}
		}
		fetchDependents()
	} else {
		Product.get({
			id: $stateParams.id
		}).$promise.then(function(product) {
			product.subtype = product.subtype && product.subtype._id
			product.type = product.type && product.type._id
			product.innerMetalType = product.innerMetalType && product.innerMetalType._id
			product.outerMetalType = product.outerMetalType && product.outerMetalType._id
			product.insulationType = product.insulationType && product.insulationType._id
			$scope.item = product;

			$scope.notes = toNotes(product)
			fetchDependents()
		})
	}
	$scope.change = function() {
		$scope.ctx.formChanged = true;
	}
	$scope.productTypeChanged = function() {
		$scope.filterProductSubtypes()
	}
	$scope.productSubtypeName = function(id) {
		var subtype = _.find($scope.productSubtypes, function(o){
			return o._id == id
		})
		return (subtype) ? subtype.name : ""
	}
	function fetchDependents() {
		$scope.shapes = [
			"ROU",
			"SQU",
			"REC",
			"OVA"
		];
		$scope.styles = [
			"",
			"TAPER",
			"STEP",
		];
		$scope.couplerTypes = [
			"",
			"ECCENTRIC",
			"CONCENTRIC",
		];
		$scope.baffleVoltages = [
			"24",
			"120",
			"240"
		];

		$scope.voltages = _.map($scope.baffleVoltages, function(voltage) {
			var base = {value: voltage, selected: false}

			if (_.contains($scope.item.voltages, voltage)) {
				base.selected = true;
			}

			return base
		})

		VentMaterial.query().$promise.then(function(ventMaterials){
			$scope.ventMaterials = _.map(ventMaterials, function(ventMaterial){
				var existing = _.find($scope.item.ventMaterials, function(vm){
					return vm._id == ventMaterial._id
				})
				if (existing){
					ventMaterial.selected = true;
				}
				return ventMaterial
			})
		})
		Fitting.query({
			limit: 1000,
			active: true
		}).$promise.then(function(fittings){
			fittings = _.sortBy(fittings, function(f){
				return f.name
			})
			$scope.fittings = fittings;
			$scope.selectFitting()
		})
		Baffle.query().$promise.then(function(baffles){
			$scope.baffles = baffles;
			$scope.selectBaffle()
		})
		MetalType.query().$promise.then(function(result){
			$scope.metalTypes = result;
		})
		InsulationType.query().$promise.then(function(result){
			$scope.insulationTypes = result;
		})
		ProductSubtype.query().$promise.then(function(result){{
			$scope.allProductSubtypes = Util.sortUncased(result, "name")
			_.each($scope.allProductSubtypes, function(o){
				console.log("productSubtype: "+o.name)
			})

			$scope.filterProductSubtypes()
		}})
		Fan.query().$promise.then(function(result){
			$scope.fans = _.sortBy(result, function(fan){
				return fan.name;
			})
		})
		Manufacturer.query().$promise.then(function(result){{
			$scope.manufacturers = result
			$scope.enervexManufacturer = _.find($scope.manufacturers, function(o){
				return o.isDefault
			})
			if ($stateParams.id == "new") {
				//default to enervex
				var manufacturer = _.find($scope.manufacturers, function(o){
					return o.isDefault
				})
				$scope.item.manufacturer = manufacturer._id
			} else {
				if ($scope.item.manufacturer) {
					$scope.item.manufacturer = $scope.item.manufacturer._id
				}
			}
		}})

	}
	$scope.selectedProductType = function() {
		return _.find($scope.productTypes, function(productType) {
			return productType._id == $scope.item.type
		})
	}
	$scope.filterProductSubtypes = function() {
		var productType = $scope.selectedProductType()
		$scope.productSubtypes = _.filter($scope.allProductSubtypes,function(o){
			var has = _.find(o.productTypes, function(p){
				return p._id == (productType && productType._id)
			})
			return (has) ? true : false
		})
	}
	$scope.selectFitting = function() {
		var fitting = _.find($scope.fittings, function(fitting){
			return fitting._id == $scope.item.fitting && $scope.item.fitting._id;
		})
		$scope.selectedFitting= fitting;
	}
	$scope.selectBaffle = function() {
		var baffle = _.find($scope.baffles, function(baffle){
			return baffle._id == $scope.item.baffle && $scope.item.baffle._id;
		})
		$scope.selectedBaffle= baffle;
	}
	ProductType.query().$promise.then(function(result){
		var productTypes = _.filter(result, function(o){
			return o.active
		})
		$scope.productTypes = _.sortBy(productTypes, function(o){
			return o.name.toLowerCase()
		})
	})
	$scope.hasProductTypeField = function(field){
		var productType = _.find($scope.productTypes, function(pt){
			return pt._id == ($scope.item && $scope.item.type)
		})
		if (productType) {
			var contains = _.find(productType.fields, function(_field){
				return _field == field
			})
			return contains ? true : false
		}
		return false
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.item);
			payload = _.omit(payload, "__v");
			var notes = _.map($scope.notes, function(n){
				return n.value && n.value != '' ? n.value : null
			})
			notes = _.compact(notes)
			if (!payload.schedule) {
				payload.schedule = {}
			}
			payload.schedule.notes = notes
			payload.ventMaterials = _.filter($scope.ventMaterials, function(a){
				return a.selected
			})
			payload.voltages = _.filter($scope.voltages, function(a){
				return a.selected
			}).map(function (w) {
				return w.value
			})
			if (payload.type == "") {
				delete payload.type;
			}
			if (!$scope.item._id) {
				uploadAsset().then(function(result) {
					//set payload
					if (result) {
						payload.image = result._id
					}
					Product.save(payload).$promise.then(function(result) {
						$scope.success = true;
						Flash.create('success', '<strong>Success!</strong> Successfully created Product.', 0, {}, true);
						$state.go("product", {
							id: result._id
						}, {
							inherit: false,
							reload: true
						})
					}).catch(function(err) {

						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				})
			} else {
				uploadAsset().then(function(result) {
					if (result) {
						payload.image = result._id
					}
					Product.update({
						id: $scope.item._id
					}, payload).$promise.then(function(result) {

						$scope.success = true;
						Flash.create('success', '<strong>Success!</strong> Successfully updated Product.', 0, {}, true);
					}).catch(function(err) {

						$scope.errors = {};
						$scope.errors.other = 'Problem saving';
						var message = (err.data && err.data.message) || "There was an error saving";
						Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
					})
				})
			}
		} else {
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
	function uploadAsset(){
		if ($scope.hasProductTypeField('image') && $scope.uploader.queue.length >0){
			var fd = new FormData();
			var data = $scope.uploader.queue[0];
			fd.append('asset', data._file);
			$scope.uploader.clearQueue()
			return Image.save({},fd).$promise.then(function(asset){
				return $q.resolve(asset)
			});
		} else {
			return $q.resolve(null)
		}
	}
	$scope.addNote = function() {
		$scope.notes.push({value:''})
	}
	$scope.removeNote = function(index) {
		$scope.notes.splice(index, 1)
	}
});
